import { differenceInDays, format, isFuture } from "date-fns"
import { fr } from "date-fns/locale"

const getDate = (date: Date | number | string) => (typeof date === "string" ? new Date(date) : date)

export const formatDate = (date: Date | number | string, pattern: string) =>
  format(getDate(date), pattern, { locale: fr })

export const isRecent = (date: Date | number | string) => differenceInDays(new Date(), getDate(date)) < 180

export const dateStr = (date = new Date()) => date.toISOString().split("T")[0]

export type DateType = "upcoming" | "recent" | "past"

export const LABELS: Record<DateType, string> = {
  upcoming: "à venir",
  recent: "récent",
  past: "passé",
}

export const getDateLabel = (event: AgendaEvent) => {
  const date = new Date(event.date)
  const type: DateType = isFuture(date) ? "upcoming" : isRecent(date) ? "recent" : "past"
  return LABELS[type]
}
